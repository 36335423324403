import React from 'react'
import { Link } from 'gatsby'
import facebook from '../img/social/facebook.svg'
import logo from '../img/logoENG.webp'
import home from '../img/home.png'
import pl from '../img/flags/pl.jpg'
import ru from '../img/flags/ru.jpg'
import en from '../img/flags/en.jpg'
import "./layout.css"
import { ThemeToggler } from "gatsby-plugin-dark-mode";

const Navbar = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      navBarActiveClass: '',
      activeMenu1: false,
      activeMenu2: false,
      activeMenu3: false,
      activeMenu4: false,
      buttonBlack: '',
      buttonBlack2: '',
      buttonBlack3: '',
      buttonBlack4: '',
      jezyki: '',
    }
  }

  jezykiOnClick = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        jezyki: !this.state.jezyki,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.jezyki
          ? this.setState({
            jezyki: 'jezykiDrop',
          })
          : this.setState({
            jezyki: '',
          })
      }
    )
  }
  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              navBarActiveClass: 'is-active',
            })
          : this.setState({
              navBarActiveClass: '',
            })
      }
    )
  }

  MenuOnclick = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        activeMenu1: !this.state.activeMenu1,

      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.activeMenu1
          ? this.setState({
            activeItems: 'activeItems',
            activeItems2: '',
            activeItems3: '',
            activeItems4: '',
            activeMenu2: false,
            activeMenu3: false,
            activeMenu4: false,
            buttonBlack: 'buttonBlack',
            buttonBlack2: '',
            buttonBlack3: '',
            buttonBlack4: '',
          })
          : this.setState({
            activeItems: '',
            activeItems2: '',
            activeItems3: '',
            activeItems4: '',
            buttonBlack: '',
            buttonBlack2: '',
            buttonBlack3: '',
            buttonBlack4: '',
          })
      }
    )
  }
  MenuOnclick2 = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        activeMenu2: !this.state.activeMenu2,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.activeMenu2
          ? this.setState({
            activeItems2: 'activeItems2',
            activeItems: '',
            activeItems3: '',
            activeItems4: '',
            activeMenu1: false,
            activeMenu3: false,
            activeMenu4: false,
            buttonBlack: '',
            buttonBlack2: 'buttonBlack',
            buttonBlack3: '',
            buttonBlack4: '',
          })
          : this.setState({
            activeItems: '',
            activeItems2: '',
            activeItems3: '',
            activeItems4: '',
            buttonBlack: '',
            buttonBlack2: '',
            buttonBlack3: '',
            buttonBlack4: '',
          })
      }
    )
  }
  MenuOnclick3 = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        activeMenu3: !this.state.activeMenu3,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.activeMenu3
          ? this.setState({
            activeItems3: 'activeItems3',
            activeItems: '',
            activeItems2: '',
            activeItems4: '',
            activeMenu1: false,
            activeMenu2: false,
            activeMenu4: false,
            buttonBlack: '',
            buttonBlack2: '',
            buttonBlack3: 'buttonBlack',
            buttonBlack4: '',
          })
          : this.setState({
            activeItems: '',
            activeItems2: '',
            activeItems3: '',
            activeItems4: '',
            buttonBlack: '',
            buttonBlack2: '',
            buttonBlack3: '',
            buttonBlack4: '',
          })
      }
    )
  }
  MenuOnclick4 = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        activeMenu4: !this.state.activeMenu4,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.activeMenu4
          ? this.setState({
            activeItems4: 'activeItems4',
            activeItems: '',
            activeItems2: '',
            activeItems3: '',
            activeMenu1: false,
            activeMenu2: false,
            activeMenu3: false,
            buttonBlack: '',
            buttonBlack2: '',
            buttonBlack3: '',
            buttonBlack4: 'buttonBlack',
          })
          : this.setState({
            activeItems: '',
            activeItems2: '',
            activeItems3: '',
            activeItems4: '',
            buttonBlack: '',
            buttonBlack2: '',
            buttonBlack3: '',
            buttonBlack4: '',
          })
      }
    )
  }

  render() {
    return (
      <div className="Navbar-Main">
        <nav
          className="navbar is-transparent"
          role="navigation"
          aria-label="main-navigation"
        >
          <div className="container">
            <div className="navbar-brand">
              {/* Hamburger menu */}
              <div
                className={`navbar-burger burger ${this.state.navBarActiveClass}`}
                data-target="navMenu"
                onClick={() => this.toggleHamburger()}
              >
                <span />
                <span />
                <span />
              </div>
            </div>
            <div
              id="navMenu"
              className={`navbar-menu ${this.state.navBarActiveClass}`}
            >
              {" "}
              <Link to="/indexENG" className="navbar-item" title="Home">
                <img alt=" " src={home} alt="Ekochron" />
              </Link>
              <div className="navbar-end has-text-centered flagiNav">
                <div
                  className="navbar-item"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => this.jezykiOnClick()}
                >
                  <span className="icon noselect flagaMain">
                    <img alt=" " src={en} alt="Facebook" />
                    <p id="arrow">↓</p>
                  </span>
                </div>
                <div className={`dropdown-content ${this.state.jezyki}`}>
                  <ul className="Ul">
                    <a href="/">
                      PL{" "}
                      <img
                        alt=" "
                        src={pl}
                        alt="Facebook"
                        className="FlagImage"
                      />
                    </a>
                  </ul>
                  <ul className="Ul">
                    <a href="/indexRU">
                      RU{" "}
                      <img
                        alt=" "
                        src={ru}
                        alt="Facebook"
                        className="FlagImage"
                      />
                    </a>
                  </ul>
                  <ul className="Ul">
                    <a href="/indexENG">
                      EN{" "}
                      <img
                        alt=" "
                        src={en}
                        alt="Facebook"
                        className="FlagImage"
                      />
                    </a>
                  </ul>
                </div>
              </div>
              <ThemeToggler>
                {({ theme, toggleTheme }) => (
                  <label className="DarkModeLabel noselect" title="Night mode">
                    <input
                      type="checkbox"
                      onChange={e =>
                        toggleTheme(e.target.checked ? "dark" : "light")
                      }
                      checked={theme === "dark"}
                    />{" "}
                    🌙
                  </label>
                )}
              </ThemeToggler>
              <div className="navbar-start has-text-centered" id="FirstNav">
                <Link
                  className="navbar-item klawisz"
                  to="/ocynkownia"
                  id="ocynkowniaItem"
                >
                  Galvanizing
                </Link>
                <Link className="navbar-item klawisz" to="/jakosc/indexENG">
                  Quality
                </Link>
                <Link className="navbar-item klawisz" to="/poradniki/indexENG">
                  Tutorials
                </Link>
                <Link className="navbar-item klawisz" to="/about/indexENG">
                  About
                </Link>
                <Link className="navbar-item klawisz" to="/blog/indexENG">
                  Blog
                </Link>
                <Link className="navbar-item klawisz" to="/instrukcje/indexENG">
                  Manuals
                </Link>
                <Link className="navbar-item klawisz" to="/contact/indexENG">
                  Contact
                </Link>
                <Link
                  className="navbar-item klawisz"
                  to="/certyfikaty/indexENG"
                >
                  Certificates
                </Link>
              </div>
              <div className="navbar-end has-text-centered">
                <a
                  className="navbar-item"
                  href="https://www.facebook.com/ekochron/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="icon">
                    <img alt=" " src={facebook} alt="Facebook" />
                  </span>
                </a>
              </div>
            </div>
          </div>
        </nav>
        <div className="container"></div>
        <div className="container">
          <div id="navMenu" className={`navbar-menu is-active`}>
            <div className="navbar-start has-text-centered" className="menu3">
              <div className="navbarMedium">
                <Link
                  to="/indexENG"
                  className="navbar-item"
                  title="Logo"
                  className="navbar3"
                >
                  <img
                    alt=" "
                    className="logoKlasa"
                    src={logo}
                    alt="Ekochron"
                  />
                </Link>
                <div className="szukajka">Szukajka</div>
                <div className="NavbarMediumMedium">
                  <p id="Contakt1">phone: +48 33 857 90 10</p>
                  <p id="Contakt1">+48 33 853 32 91</p>
                  <p id="Contakt2">Monday - Friday: 7:00 - 15:00</p>
                  <a id="Contakt3" href="mailto:sprzedaz@ekochron.pl">
                    mail: sprzedaz@ekochron.pl
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div id="navMenu" className={`navbar-menu is-active`}>
            <div className="navbar-start has-text-centered" className="menu2">
              <div className="dropdown">
                <button
                  onClick={() => this.MenuOnclick()}
                  id="KomunikacjaDachowa"
                  className={`dropbtn ${this.state.buttonBlack}`}
                >
                  1. Roof communication ↓
                </button>
                <div
                  className={`dropdown-content lewy NavJeden ${this.state.activeItems}`}
                >
                  <dl>
                    <dt>
                      <a href="/komunikacjadachowa/lawakominiarska">
                        1.1 Chimney sweeper bench
                      </a>
                    </dt>
                    <dt>
                      <a href="/komunikacjadachowa/laczniklawkominiarskich">
                        1.2 Chimney sweeper bench coupler
                      </a>
                    </dt>
                    <dt>
                      <a href="/komunikacjadachowa/stopienkominiarskiwaski">
                        1.3 Chimney sweeper step narrow
                      </a>
                    </dt>
                    <dt>
                      <a href="/komunikacjadachowa/mocowniklawykominiarskiej">
                        1.4 Chimney sweeper bench fastener
                      </a>
                    </dt>
                    <dt>
                      <a href="/komunikacjadachowa/przysciennymocowniklaw">
                        1.5 Wall bench fastener
                      </a>
                    </dt>
                    <dt>
                      <a href="/komunikacjadachowa/wsporniklawystopniagiety">
                        1.6 Bench step support bracket angled
                      </a>
                    </dt>
                    <dd>
                      <a href="/komunikacjadachowa/wsporniklawystopniagietypodklejony">
                        1.6.1 Bench step support bracket angled taped off
                      </a>
                    </dd>
                    <dd>
                      <a href="/komunikacjadachowa/wsporniklawystopniagietyzgumaepdm">
                        1.6.2 Bench step support bracket angled with EPDM rubber
                      </a>
                    </dd>
                    <dd>
                      <a href="/komunikacjadachowa/wsporniklawystopniakarpiowkajednoelementowy">
                        1.6.3 Bench step support bracket scale tiles (straight)
                        one-element
                      </a>
                    </dd>
                    <dd>
                      <a href="/komunikacjadachowa/wsporniklawystopnianalupek">
                        1.6.4 Bench step support bracket slate tiles
                      </a>
                    </dd>
                  </dl>
                  <dl>
                    <dt>
                      <a href="/komunikacjadachowa/wsporniklawystopniadlablachodachowek">
                        1.7 Bench step support bracket profiled sheet
                      </a>
                    </dt>
                    <dt>
                      <a href="/komunikacjadachowa/wsporniklawystopniauniwersalny">
                        1.8 Bench step support bracket multi-purpose
                      </a>
                    </dt>
                    <dt>
                      <a href="/komunikacjadachowa/wsporniklawystopniakarpiowkizpodporka">
                        1.9 Wspornik ławy stopnia karpiówki z podpórką
                        (dwuelementowy)
                      </a>
                    </dt>
                    <dt>
                      <a href="/komunikacjadachowa/wsporniklawystopniadachowkowytypcic1">
                        1.10 Bench step support bracket tiles type C & C1
                      </a>
                    </dt>
                    <dt>
                      <a href="/komunikacjadachowa/wsporniklawystopniadlablachprofilowanychtypdecra">
                        1.11 Bench step support bracket for Decra profiled
                        sheets
                      </a>
                    </dt>
                    <dt>
                      <a href="/komunikacjadachowa/stabilizatorwspornikowlawystopnia">
                        1.12 Bench step support bracket gap plate
                      </a>
                    </dt>
                    <dt>
                      <a href="/komunikacjadachowa/barierkalawkominiarskich">
                        1.13 Bench railing
                      </a>
                    </dt>
                    <dt>
                      <a href="/komunikacjadachowa/drabinadachowa">
                        1.14 Roof ladder
                      </a>
                    </dt>
                  </dl>
                </div>
              </div>
              <div className="dropdown">
                <button
                  onClick={() => this.MenuOnclick2()}
                  id="OchronaPrzeciwsniegowa"
                  className={`dropbtn ${this.state.buttonBlack2}`}
                >
                  2. Snow retention ↓
                </button>
                <div
                  className={`dropdown-content lewy NavDwa ${this.state.activeItems2}`}
                >
                  <dl>
                    <dt>
                      <a href="/ochronaprzeciwsniegowa/plotkiprzeciwsniegowe20i15">
                        2.1 Snow fences 20 & 15 cm
                      </a>
                    </dt>
                    <dd>
                      <a href="/ochronaprzeciwsniegowa/lacznikplotkaprzeciwsniegowego">
                        2.1.1 Snow fence coupler
                      </a>
                    </dd>
                    <dd>
                      <a href="/ochronaprzeciwsniegowa/wspornikplotkadachowkowytypa">
                        2.1.2 Snow fence support bracket scale tiles
                      </a>
                    </dd>
                    <dd>
                      <a href="/ochronaprzeciwsniegowa/wspornikplotkadlakarpiowki">
                        2.1.3 Wspornik płotka dla karpiówki
                      </a>
                    </dd>
                    <dd>
                      <a href="/ochronaprzeciwsniegowa/wspornikplotkadachowkowytypbn">
                        2.1.4 Snow fence support bracket tiles type BN
                      </a>
                    </dd>
                    <dd>
                      <a href="/ochronaprzeciwsniegowa/wspornikplotkadlablachodachowek">
                        2.1.5 Snow fence support bracket profiled sheet
                      </a>
                    </dd>
                    <dd>
                      <a href="/ochronaprzeciwsniegowa/wspornikplotkadlablachdecra">
                        2.1.6Snow fence support bracket for Decra profiled
                        sheets
                      </a>
                    </dd>
                    <dd>
                      <a href="/ochronaprzeciwsniegowa/wspornikplotkauniwersalnytypa">
                        2.1.7 Snow fence support bracket multi-purpose type A
                      </a>
                    </dd>
                    <dd>
                      <a href="/ochronaprzeciwsniegowa/wspornikplotkauniwersalnytypb">
                        2.1.8 Snow fence support bracket multipurpose type B -
                        High
                      </a>
                    </dd>
                    <dd>
                      <a href="/ochronaprzeciwsniegowa/wspornikplotkanalupek">
                        2.1.9 Snow fence support bracket slate tiles
                      </a>
                    </dd>
                    <dd>
                      <a href="/ochronaprzeciwsniegowa/wspornikplotkadachowkowytypcic1">
                        2.1.12 Wspornik płotka dachówkowy typ C i C1
                      </a>
                    </dd>
                  </dl>
                  <dl>
                    <dt>
                      <a href="/ochronaprzeciwsniegowa/belkidrewniane">
                        2.3. Belki drewniane ø120
                      </a>
                    </dt>
                    <dd>
                      <a href="/ochronaprzeciwsniegowa/wspornikbelkidachowkowytypa">
                        2.3.1 Wspornik belki dachówkowy typ A
                      </a>
                    </dd>
                    <dd>
                      <a href="/ochronaprzeciwsniegowa/wspornikbelkidachowkowytypb">
                        2.3.2 Wspornik belki dachówkowy typ B
                      </a>
                    </dd>
                    <dd>
                      <a href="/ochronaprzeciwsniegowa/wspornikbelkikarpiowki">
                        2.3.3 Wspornik belki karpiówki
                      </a>
                    </dd>
                    <dd>
                      <a href="/ochronaprzeciwsniegowa/wspornikbelkidlablachodachowek">
                        2.3.4 Wspornik belki dla blachodachówek
                      </a>
                    </dd>
                    <dd>
                      <a href="/ochronaprzeciwsniegowa/wspornikbelkiuniwersalny">
                        2.3.5 Wspornik belki uniwersalny
                      </a>
                    </dd>
                    <dt>
                      <a href="/ochronaprzeciwsniegowa/ruryprzeciwniegowe">
                        2.2 Snow tubes Ø32mm
                      </a>
                    </dt>
                    <dd>
                      <a href="/ochronaprzeciwsniegowa/wspornikrurdachowkowytypa">
                        2.2.1 Tube support bracket tiles type A
                      </a>
                    </dd>
                    <dd>
                      <a href="/ochronaprzeciwsniegowa/wspornikrurdachowkowytypb">
                        2.2.2 Tube support bracket tiles type B
                      </a>
                    </dd>
                    <dd>
                      <a href="/ochronaprzeciwsniegowa/wspornikrurdachowkowytypcic1">
                        2.2.3 Tube support bracket tiles type C & C1
                      </a>
                    </dd>
                    <dd>
                      <a href="/ochronaprzeciwsniegowa/wspornikrurdlakarpiowki">
                        2.2.4 Tube support bracket scale tiles
                      </a>
                    </dd>
                    <dd>
                      <a href="/ochronaprzeciwsniegowa/wspornikrurdlablachodachowek">
                        2.2.5 Tube support bracket profiled sheet
                      </a>
                    </dd>
                    <dd>
                      <a href="/ochronaprzeciwsniegowa/wspornikrurnalupek">
                        2.2.6 Tube support bracket slate tiles
                      </a>
                    </dd>
                    <dd>
                      <a href="/ochronaprzeciwsniegowa/wspornikruruniwersalny">
                        2.2.7 Tube support bracket multi-purpose
                      </a>
                    </dd>
                    <dd>
                      <a href="/ochronaprzeciwsniegowa/lacznikrur">
                        2.2.8 Tube coupler
                      </a>
                    </dd>
                    <dd>
                      <a href="/ochronaprzeciwsniegowa/korekzaslepkarur">
                        2.2.9 Tube cap
                      </a>
                    </dd>
                    <dd>
                      <a href="/ochronaprzeciwsniegowa/wspornikrelingowyrurprzeciwsniegowych">
                        2.2.10 Wspornik relingowy rur przeciwśniegowych
                      </a>
                    </dd>
                  </dl>
                  <dl>
                    <dt>
                      <a href="/ochronaprzeciwsniegowa/rozbijaczesnieguisniegolapy">
                        2.4. Rozbijacze śniegu i śniegołapy
                      </a>
                    </dt>
                    <dd>
                      <a href="/ochronaprzeciwsniegowa/rozbijaczsniegudachowkowy">
                        2.4.1 Rozbijacz śniegu dachówkowy
                      </a>
                    </dd>
                    <dd>
                      <a href="/ochronaprzeciwsniegowa/rozbijaczsniegudlakarpiowki">
                        2.4.2 Rozbijacz śniegu dla karpiówki
                      </a>
                    </dd>
                    <dd>
                      <a href="/ochronaprzeciwsniegowa/rozbijaczsniegudlablachodachowek">
                        2.4.3 Rozbijacz śniegu dla blachodachówek
                      </a>
                    </dd>
                    <dd>
                      <a href="/ochronaprzeciwsniegowa/rozbijaczsnieguprosty">
                        2.4.4 Rozbijacz śniegu prosty
                      </a>
                    </dd>
                    <dd>
                      <a href="/ochronaprzeciwsniegowa/rozbijaczsniegustoperdachowy">
                        2.4.5 Rozbijacz śniegu - stoper dachowy
                      </a>
                    </dd>
                    <dd>
                      <a href="/ochronaprzeciwsniegowa/rozbijaczsniegustopernagnot">
                        2.4.6 Rozbijacz śniegu - stoper na gnot
                      </a>
                    </dd>
                    <dd>
                      <a href="/ochronaprzeciwsniegowa/sniegolaplopatka">
                        2.4.7 Śniegołap łopatka
                      </a>
                    </dd>
                    <dd>
                      <a href="/ochronaprzeciwsniegowa/sniegolapkrzyzslowacki">
                        2.4.8 Śniegołap krzyż słowacki
                      </a>
                    </dd>
                  </dl>
                </div>
              </div>
              <div className="dropdown">
                <button
                  onClick={() => this.MenuOnclick3()}
                  id="DachoweHaki"
                  className={`dropbtn ${this.state.buttonBlack3}`}
                >
                  3. Safety roof hooks type A ↓
                </button>
                <div
                  className={`dropdown-content prawy NavTrzy ${this.state.activeItems3}`}
                >
                  <dl>
                    <dt>
                      <a href="/dachowehakizabezpieczajacetypa/dachowehakizabezpieczajacetypa">
                        3 Кровельные защитные крюки типа а
                      </a>
                    </dt>
                    <dd>
                      <a href="/dachowehakizabezpieczajacetypa/dachowyhakzabezpieczajacydachowkowytypa">
                        3.1 Кровельные страховочные крюки типа А
                      </a>
                    </dd>
                    <dd>
                      <a href="/dachowehakizabezpieczajacetypa/dachowyhakzabezpieczajacydachowkowytypb">
                        3.2 Кровельный страховочный крюк типа В
                      </a>
                    </dd>
                    <dd>
                      <a href="/dachowehakizabezpieczajacetypa/dachowkowyhakzabezpieczajacydlablachodachowekmod35">
                        3.3 Кровельный страховочный крюк для металлочерепицы
                        мод. 35
                      </a>
                    </dd>
                    <dd>
                      <a href="/dachowehakizabezpieczajacetypa/dachowyhakzabezpieczajacyuniwersalnydopokrycplaskich">
                        3.4 Кровельный страховочный крюк универсальный для
                        плоской кровли
                      </a>
                    </dd>
                  </dl>
                </div>
              </div>
              <div className="dropdown">
                <button
                  onClick={() => this.MenuOnclick4()}
                  id="PozostaleWyroby"
                  className={`dropbtn ${this.state.buttonBlack4}`}
                >
                  4. Other products ↓
                </button>
                <div
                  className={`dropdown-content prawy NavCztery ${this.state.activeItems4}`}
                >
                  <dl>
                    <dt>
                      <a href="/pozostalewyroby/uchwytnarabekstojacy">
                        4.1 Uchwyt na rąbek stojący
                      </a>
                    </dt>
                    <dt>
                      <a href="/pozostalewyroby/antyptakkominowy">
                        4.2 Antyptak kominowy
                      </a>
                    </dt>
                    <dd>
                      <a href="/pozostalewyroby/antyptakkominowykwadratowy">
                        4.2.1 Antyptak kominowy kwadratowy
                      </a>
                    </dd>
                    <dd>
                      <a href="/pozostalewyroby/antyptakkominowyprostokatny">
                        4.2.2 Antyptak kominowy prostokątny
                      </a>
                    </dd>
                    <dt>
                      <a href="/pozostalewyroby/antyptakkolcowy">
                        4.3 Antyptak kolcowy
                      </a>
                    </dt>
                    <dd>
                      <a href="/pozostalewyroby/antyptakkolcowytypa">
                        4.3.1 Antyptak kolcowy typ A
                      </a>
                    </dd>
                    <dd>
                      <a href="/pozostalewyroby/antyptakkolcowytypb">
                        4.3.2 Antyptak kolcowy typ B
                      </a>
                    </dd>
                    <dd>
                      <a href="/pozostalewyroby/antyptakkolcowytypc">
                        4.3.3 Antyptak kolcowy typ C
                      </a>
                    </dd>
                  </dl>
                  <dl>
                    <dt>
                      <a href="/pozostalewyroby/uchwytsolara">
                        4.4 Uchwyt solara
                      </a>
                    </dt>
                    <dd>
                      <a href="/pozostalewyroby/uchwytsolaradachowkowytypb">
                        4.4.1 Uchwyt solara dachówkowy typ B
                      </a>
                    </dd>
                    <dd>
                      <a href="/pozostalewyroby/uchwytsolaraplaski">
                        4.4.2 Uchwyt solara płaski
                      </a>
                    </dd>
                    <dt>
                      <a href="/pozostalewyroby/laczniklatdachowkowych">
                        4.7 Łącznik łat dachowych
                      </a>
                    </dt>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Navbar
